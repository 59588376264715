/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationAdminResponseAllOfLastVerificationRequester { 
    userId?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
}

