/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMembershipState } from './userMembershipState';


export interface MembershipResponse { 
    id: string;
    userId?: string;
    organizationId: string;
    organizationName?: string;
    roleId: string;
    roleName?: string;
    permissions?: Array<MembershipResponsePermissionsEnum>;
    state: UserMembershipState;
    /**
     * Indicates whether the membership is inherited.
     */
    inherited: boolean;
    /**
     * Indicates whether the membership creates inherited memberships.
     */
    createsInherited?: boolean;
    createdAt?: Date;
    modifiedAt?: Date;
}
export enum MembershipResponsePermissionsEnum {
    READ_USER = 'READ_USER',
    UPDATE_USER = 'UPDATE_USER',
    READ_USER_LIST = 'READ_USER_LIST',
    REQUEST_VERIFICATION = 'REQUEST_VERIFICATION',
    READ_ORGANIZATION_LIST = 'READ_ORGANIZATION_LIST',
    READ_ORGANIZATION = 'READ_ORGANIZATION',
    CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
    UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
    DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
    CREATE_USER_INVITATION = 'CREATE_USER_INVITATION',
    UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP',
    DELETE_MEMBERSHIP = 'DELETE_MEMBERSHIP',
    READ_OWN_PROFILE = 'READ_OWN_PROFILE',
    UPDATE_OWN_PROFILE = 'UPDATE_OWN_PROFILE',
    DELETE_OWN_PROFILE = 'DELETE_OWN_PROFILE',
    ALLOW_ALL_TEMPORARY_ROLES = 'ALLOW_ALL_TEMPORARY_ROLES',
    DEPLOY_TRANSLATIONS = 'DEPLOY_TRANSLATIONS',
    DELETE_USER = 'DELETE_USER',
    READ_DASHBOARD_LIST = 'READ_DASHBOARD_LIST',
    ALLOW_APP_ADMIN_ROLE = 'ALLOW_APP_ADMIN_ROLE',
    ALLOW_APP_TECHNICIAN_ROLE = 'ALLOW_APP_TECHNICIAN_ROLE',
    ALLOW_APP_OPERATOR_ROLE = 'ALLOW_APP_OPERATOR_ROLE',
    MANAGE_FEATURE_FLAGS = 'MANAGE_FEATURE_FLAGS',
    MANAGE_FEATURE_FLAG_ASSIGNMENTS = 'MANAGE_FEATURE_FLAG_ASSIGNMENTS'
};



