<geaui-page-content-layout>
  <ng-container page-content>
    <form class="form" [formGroup]="form">
      <section class="geaui-grid geaui-sidebar-grid form-section">
        <div class="gea-font-heading-20 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 form-heading">
          {{ 'PROFILE.FORM.PERSONAL_INFO.LABEL' | translate }}
        </div>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-2"
          [formControlName]="FORM_CONTROL_TITLE"
          [label]="'PROFILE.FORM.PERSONAL_INFO.TITLE.LABEL' | translate"
          [loading]="loading"
          [options]="titleOptions">
        </geaui-autocomplete-select-v2>
        <geaui-select-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [label]="'X.LABEL.CUSTOMER_USER_TYPE' | translate"
          [group]="form"
          [options]="customerTypeOptions"
          [loading]="loading"
          [required]="true"
          [disabled]="!!form.get(FORM_CONTROL_CUSTOMER_TYPE)?.disabled"
          formName="customerUserType"></geaui-select-v2>
        <div class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-9 geaui-col-desktop-md-1 customer-type">
          <geaui-icon-v2
            class="icon"
            [pTooltip]="'USERS.USER_DETAIL.FORM.PERSONAL_INFO.CUSTOMER_USER_TYPE_TOOLTIP' | translate"
            file="16px_c-info"
            tooltipPosition="right"
            tooltipStyleClass="p-tooltip-light-blue p-tooltip-arrow-position-start"></geaui-icon-v2>
        </div>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_FIRSTNAME"
          [group]="form"
          [label]="'X.LABEL.FIRST_NAME' | translate"
          [loading]="loading"
          [required]="true"></geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_LASTNAME"
          [group]="form"
          [label]="'X.LABEL.LAST_NAME' | translate"
          [loading]="loading"
          [required]="true"></geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_EMAIL"
          [group]="form"
          [disabled]="true"
          [floatLabelWhileDisabled]="true"
          [label]="'X.LABEL.EMAIL' | translate"
          [loading]="loading"
          [required]="true">
        </geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_PHONE"
          [group]="form"
          [label]="'X.LABEL.PHONE' | translate"
          [loading]="loading"
          [required]="false">
        </geaui-input-v2>

        <div class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-8">
          <p-divider></p-divider>
        </div>

        <div class="gea-font-heading-20 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 mt-25">
          {{ 'PROFILE.FORM.SETTINGS.LABEL' | translate }}
        </div>

        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [formControlName]="FORM_CONTROL_LANGUAGE"
          [label]="'X.LABEL.LANGUAGE' | translate"
          [loading]="loading"
          [options]="languageOptions"
          [required]="true"
          (selectChanged)="onLocaleChange()">
        </geaui-autocomplete-select-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          id="country"
          [formControlName]="FORM_CONTROL_COUNTRY"
          [disabled]="true"
          [label]="'X.LABEL.COUNTRY' | translate"
          [loading]="loading"
          [options]="countryOptions"></geaui-autocomplete-select-v2>
        <div class="geaui-col-desktop-xl-start-16 geaui-col-desktop-md-start-10 geaui-col-desktop-md-2">
          <geaui-toggle-v2
            [id]="FORM_CONTROL_OPT_MFA"
            [isLabelLeft]="true"
            [form]="form"
            [flex]="true"
            [loading]="loading"
            [disabled]="profileData?.enforcedMFA ?? false"
            (toggled)="(profileData?.optInMFA)"
            label="PROFILE.FORM.SETTINGS.OPT_IN_MFA.LABEL"
            labelClass="gea-font-body-18">
          </geaui-toggle-v2>

          @if (profileData?.id && profileData?.enforcedMFA) {
            <div class="geaui-fieldset-hint">
              <span>{{ 'PROFILE.FORM.SETTINGS.ENFORCED_MFA_HINT.LABEL' | translate }}</span>
            </div>
          }
        </div>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [formControlName]="FORM_CONTROL_TIMEZONE"
          [label]="'PROFILE.FORM.SETTINGS.TIME_ZONE.LABEL' | translate"
          [loading]="loading"
          [options]="timeZoneOptions">
        </geaui-autocomplete-select-v2>

        <span class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4">
          <geaui-link-v2 [preIcon]="'16px_trash-can'" [type]="'cancel-red'" (click)="showRemoveAccountDialog()">
            {{ 'X.LABEL.DELETE' | translate }}
          </geaui-link-v2>
        </span>
      </section>
    </form>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 class="btn" [sending]="isSending" [disabled]="isSaveButtonDisabledByForm" (click)="saveProfileUser()">
          {{ 'X.BUTTON.SAVE' | translate }}
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
