import {Component, Inject, Input} from '@angular/core';
import { WidgetStyle, widgetStyleConfig } from '../models/widget-style.config';

@Component({
  selector: 'gea-id-workspace-app-card-widget',
  templateUrl: './app-card-widget.component.html',
  styleUrls: ['./app-card-widget.component.scss'],
})
export class AppCardWidgetComponent {
  @Input() editModeActive!: boolean;
  @Input() widgetId!: string;
  @Input() widgetType!: string;
  @Input() widgetUrl?: string;
  @Input() widgetDisabled?: boolean;
  @Input() widgetImagePath!: string;
  color = 'var(--geaui-pure-white)';
  private readonly fallbackImagePath = this.appContentUrl + 'generic/icons/GEA-Generic-App.svg';
  private fallbackBgIcon = 'generic/icons/GEA-Generic-App-background.svg';

  constructor(@Inject('appContentPath') public appContentUrl: string) {
  }

  get widgetImageUrl(): string {
    return this.widgetImagePath === undefined ? this.fallbackImagePath : this.widgetImagePath;
  }

  get storageContainerName(): string {
    const splitPath = this.widgetImageUrl?.split('/') ?? [];
    return splitPath.find((s) => s.includes('strg-container-app-assets')) ?? '';
  }

  get widgetStyle(): WidgetStyle {
    return widgetStyleConfig[this.widgetType] ?? this.widgetDefaultStyle;
  }

  get widgetDefaultStyle(): WidgetStyle {
    return widgetStyleConfig['default'];
  }

  get appKey(): string {
    if (!this.widgetType) return '';
    const parts = this.widgetType.split('_');
    // parts[0] is the division
    // parts[1] is the app name
    return (parts[0] + '_' + parts[1]).toUpperCase();
  }

  get iconName(): string {
    if (!this.widgetImageUrl) {
      return this.fallbackBgIcon;
    }
    return (
      this.widgetImageUrl
        .slice(this.widgetImageUrl.indexOf(this.storageContainerName) + this.storageContainerName.length + 1)
        .replace('.svg', '')
    );
  }
}
