import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  ApiErrorResponse,
  DialogV2Service,
  ErrorHandlerV2Service,
  PermissionKey,
  PermissionsState,
  SelectOption,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { filter, first, Observable, startWith } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { OrganizationDetailService } from '../services/organization-detail.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NEW_ORGA_ID } from '../../models/organizations.routing';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {
  FormService,
  OrganizationDetailResponse,
  OrganizationType,
  OrganizationService,
  OrganizationDetail,
} from '@gea-id/shared';

@Component({
  selector: 'gea-id-organization-info',
  templateUrl: './organization-info.component.html',
  styleUrl: './organization-info.component.scss',
})
export class OrganizationInfoComponent implements OnInit {
  @Input() private orgaId = '';
  protected countries: Observable<SelectOption<string>[]> = this.organizationDetailService.countriesOptions$;
  protected loading = true;
  protected hasNoUpdatePermission = true;

  form = this.fb.group({
    orgaName: ['', Validators.required],
    type: new FormControl<OrganizationType | null>(null, [
      Validators.required,
      (control: AbstractControl) => (control.value === OrganizationType.UNKNOWN ? { required: true } : null),
    ]),
    customerNumber: [''],
    shippingAddress: this.fb.group({
      street: ['', [Validators.required, Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.maxLength(40)]],
      country: ['', [Validators.required]],
      zipCode: ['', [Validators.required, Validators.maxLength(20)]],
    }),
    billingAddressSame: [true],
    billingAddress: this.fb.nonNullable.group({
      street: ['', Validators.maxLength(100)],
      city: ['', Validators.maxLength(40)],
      country: [''],
      zipCode: ['', Validators.maxLength(20)],
    }),
  });

  protected get isInvoiceDisabled() {
    return !!this.form.controls.billingAddressSame.value;
  }

  protected get hasDeletePermission(): Observable<boolean> {
    return this.store
      .select(PermissionsState.userPermissions)
      .pipe(map((permissions) => permissions.includes(PermissionKey.DELETE_ORGANIZATION)));
  }

  initHasNoUpdatePermission(): void {
    this.store
      .select(PermissionsState.userPermissions)
      .pipe(
        startWith([] as PermissionKey[]),
        map((permissions) => {
          return this.isNewOrga
            ? !permissions.includes(PermissionKey.CREATE_ORGANIZATION)
            : !this.organizationDetailService.isUpdateable;
        })
      )
      .subscribe((hasNoUpdatePermission) => {
        this.hasNoUpdatePermission = hasNoUpdatePermission;
      });
  }

  protected get orgaTypes(): Observable<SelectOption<OrganizationType>[]> {
    return this.organizationDetailService.orgaTypes$.pipe(
      map((orgaTypes) =>
        orgaTypes.map((o) => ({
          value: o.type,
          nameKey: `X.ORGA_TYPE.${o.type}`,
        }))
      )
    );
  }

  protected get isNewOrga(): boolean {
    return this.orgaId === NEW_ORGA_ID;
  }

  constructor(
    protected store: Store,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private organizationDetailService: OrganizationDetailService,
    private errorHandler: ErrorHandlerV2Service,
    private _destroyRef: DestroyRef,
    private router: Router,
    private confirmService: DialogV2Service,
    private snackbar: SnackbarService,
    private destroyRef: DestroyRef,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.initHasNoUpdatePermission();

    this.form.controls.billingAddressSame.valueChanges
      .pipe(startWith(this.form.value.billingAddressSame), takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => {
        if (value) {
          this.form.controls.billingAddress.disable();
        } else {
          this.form.controls.billingAddress.enable();
        }
      });

    this.loading = true;

    if (this.isNewOrga) {
      this.loading = false;
      return;
    } else {
      this.form.controls.type.disable();
      this.form.controls.billingAddress.controls.country.disable();
      this.form.controls.shippingAddress.controls.country.disable();
    }

    // init with real OrgaId
    this.organizationDetailService.initialOrgaData$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((orga): orga is OrganizationDetailResponse => !!orga && orga.id === this.orgaId)
      )
      .subscribe((orga: OrganizationDetailResponse) => {
        this.fillForm(orga);
        this.loading = false;
      });

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.formService.notifyFormChanges(this.form.dirty);
    });
  }

  protected save() {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      throw new Error('Form is invalid');
    }

    this.organizationDetailService.update({ orgaType: this.form?.value?.type });

    const orgaUpdate = this.formAsOrgaDto();
    this.loading = true;
    if (this.isNewOrga) {
      this.organizationService
        .createOrga(orgaUpdate)
        .pipe(first(), takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (orga: OrganizationDetailResponse) => {
            this.organizationDetailService.init(orga.id);
            this.organizationDetailService.isUpdateable = true;
            this.orgaId = orga.id;
            this.snackbar.add({
              severity: 'success',
              detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
              summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            });
            this.loading = false;
            void this.router.navigate(['administration', 'organization', orga.id, 'info']);
          },
          error: (error: ApiErrorResponse) => {
            if (error.error.errorCode === 'GEAID_11_0001' && error.error.message.includes('Organization name already exists')) {
              error.error.errorCode = 'GEAID_11_0001_1';
            }
            this.errorHandler.handleError(error);
            this.loading = false;
          },
        });
    } else {
      this.organizationDetailService
        .save(orgaUpdate)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.snackbar.add({
              severity: 'success',
              detail: 'X.MESSAGE.SUCCESS.DETAIL.UPDATE',
              summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            });
            this.loading = false;
          },
          error: (error: ApiErrorResponse) => {
            this.errorHandler.handleError(error);
            this.loading = false;
          },
        });
    }
  }

  protected deleteOrga() {
    this.confirmService.open({
      title: 'X.BUTTON.DELETE',
      message: 'X.PROMPT.DELETECONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => this.removeOrga(),
    });
  }

  private removeOrga() {
    this.loading = true;
    this.organizationService
      .deleteOrga(this.orgaId)
      .pipe(
        first(),
        finalize(() => {
          this.loading = false;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
          void this.router.navigate(['organization']);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
        },
      });
  }

  private formAsOrgaDto(): OrganizationDetail {
    return {
      name: this.form.value.orgaName ?? '',
      type: this.form.value.type ?? OrganizationType.OTHER,
      customerNumber: this.form.value.customerNumber ?? '',
      shippingAddress: {
        street: this.form.value.shippingAddress?.street ?? '',
        country: this.form.value.shippingAddress?.country ?? '',
        city: this.form.value.shippingAddress?.city ?? '',
        zipCode: this.form.value.shippingAddress?.zipCode ?? '',
      },
      isBillingAddressSame: !!this.form.value.billingAddressSame,
      billingAddress: this.form.value.billingAddressSame
        ? undefined
        : {
            street: this.form.value.billingAddress?.street ?? '',
            country: this.form.value.billingAddress?.country ?? '',
            city: this.form.value.billingAddress?.city ?? '',
            zipCode: this.form.value.billingAddress?.zipCode ?? '',
          },
    };
  }

  private fillForm(orga: OrganizationDetailResponse) {
    this.form.patchValue({
      orgaName: orga.name,
      type: orga.type,
      customerNumber: orga.customerNumber,
      shippingAddress: {
        zipCode: orga.shippingAddress.zipCode,
        city: orga.shippingAddress.city,
        country: orga.shippingAddress.country,
        street: orga.shippingAddress.street,
      },
      billingAddress: {
        zipCode: orga.billingAddress?.zipCode ?? '',
        city: orga.billingAddress?.city ?? '',
        country: orga.billingAddress?.country ?? 'DE',
        street: orga.billingAddress?.street ?? '',
      },
      billingAddressSame: orga.billingAddressSame,
    });
    this.form.updateValueAndValidity();
  }

  protected linkCustomer(customerNumber: string) {
    this.form.patchValue({ customerNumber: customerNumber });
  }
}
