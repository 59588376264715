import { Component, OnInit } from '@angular/core';

import {
  ApiErrorResponse,
  App,
  DialogV2Service,
  ErrorHandlerV2Service,
  GoogleAnalyticsService,
  IconColor,
  LayoutService,
  PermissionKey,
  PermissionsState,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { first, Observable, take } from 'rxjs';

import { AppAndIconService } from '../services/app-and-icon.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { OrganizationService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-all-apps',
  templateUrl: './all-apps.component.html',
  styleUrls: ['./all-apps.component.scss'],
})
export class AllAppsComponent implements OnInit {
  loading = true;
  hasVerificationPermission = false;
  selectedApp?: string;
  appList?: App[];
  verifyAppList?: App[];

  constructor(
    private appService: AppAndIconService,
    private dialog: DialogV2Service,
    private translate: TranslateService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackbarService: SnackbarService,
    public layout: LayoutService,
    public orgaService: OrganizationService,
    private gaService: GoogleAnalyticsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store.select(PermissionsState.userPermissions).subscribe((permissions) => {
      this.hasVerificationPermission = permissions.includes(PermissionKey.REQUEST_VERIFICATION);
    });

    /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
    this.appService
      .getAppsAndInitIcons()
      .pipe(take(1))
      .subscribe(({ appList, verifyAppList }) => {
        this.loading = false;
        this.appList = appList;
        this.verifyAppList = verifyAppList;
      });
    /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */

    if (this.gaService.loggedInUser) {
      this.gaService.metaPush('All Apps');
    } else {
      // eslint-disable-next-line no-console
      console.warn('was not able to send ga event');
    }
  }

  selectApp(selectedApp: string) {
    this.selectedApp = selectedApp;
  }

  unlockAppsIconColor(): IconColor {
    return this.hasVerificationPermission ? 'white' : 'grey';
  }

  onStartVerification() {
    if (this.hasVerificationPermission) {
      this.dialog.open({
        closable: false,
        title: 'DASHBOARD.VERIFY.START-TITLE',
        message: 'DASHBOARD.VERIFY.START-MESSAGE',
        yes: 'X.BUTTON.CONFIRM',
        no: 'X.BUTTON.CANCEL',
        confirmCallback: () => this.requestVerification(),
      });
    }
  }

  requestVerification(): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const observable: Observable<void> = this.orgaService.requestOrganizationVerification();
    // handle error and success outside of ConfirmPrompt to avoid circular dependency issues
    observable.pipe(first()).subscribe({
      next: () => {
        this.snackbarService.add({
          summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          severity: 'success',
          detail: 'DASHBOARD.VERIFY.SUCCESS',
        });
      },
      error: (e: ApiErrorResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.errorHandlerService.handleError(e);
      },
    });
    return observable;
  }
}
