/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { BusinessRelationUpdateCreateRequestV1 } from './businessRelationUpdateCreateRequestV1';
import { SiteRequest } from './siteRequest';


export interface OrganizationPutRequest { 
    name?: string;
    shippingAddress?: Address;
    billingAddress?: Address;
    sites?: Array<SiteRequest>;
    ignoreSites?: boolean;
    customerNumber?: string;
    businessRelations?: Array<BusinessRelationUpdateCreateRequestV1>;
    ignoreRelations?: boolean;
}

