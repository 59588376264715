import { map, Observable } from 'rxjs';
import { Membership, MembershipState } from '@gea/digital-ui-lib';

import { BaseMembership, extractRequestParams, FilterTableSettings } from '@gea/digital-ui-lib';
import { Injectable } from '@angular/core';
import { sortMemberships } from '../utils/membership.util';
import { MembershipCreateRequest, MembershipDto, MembershipsApiService } from '../../generated/api/v1';
import { ListResponse } from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  constructor(private apiService: MembershipsApiService) {}

  getMemberships(userId: string): Observable<Membership[]> {
    return this.apiService.membershipsGet({ userId }).pipe(
      map((memberships) => memberships.map((m) => ({ ...m, state: m.state as unknown as MembershipState }))),
      map((m) => sortMemberships(m))
    );
  }

  getMembershipsPaginated(userId: string, filterSettings?: FilterTableSettings): Observable<ListResponse<Membership[]>> {
    const requestParams = filterSettings
      ? { ...extractRequestParams<object>(filterSettings), userId }
      : { page: '0', pageSize: '10', userId };

    return this.apiService.membershipsGetPaginated(requestParams).pipe(
      map((membershipsResponse) => {
        const memberShips = membershipsResponse.pageEntries.map(
          (m) => ({ ...m, state: m.state as unknown as MembershipState }) as Membership
        );
        return {
          entryCount: membershipsResponse.entryCount,
          pageEntries: sortMemberships(memberShips),
        };
      })
    );
  }

  createMembership(userid: string, membershipCreateRequest: MembershipCreateRequest) {
    return this.apiService.membershipsCreate({ userId: userid, membershipCreateRequest: membershipCreateRequest });
  }

  updateMembership(userId: string, membershipId: string, membership: BaseMembership) {
    return this.apiService.membershipsUpdate({ userId, membershipId, membershipDto: membership });
  }

  updateMemberships(userId: string, memberships: BaseMembership[]) {
    return this.apiService.membershipsPut({ userId, membershipDto: memberships as unknown as MembershipDto[] });
  }

  deleteMembership(userId: string, membershipId: string) {
    return this.apiService.membershipsDelete({ userId, membershipId });
  }

  revokeMembership(userId: string, membershipId: string) {
    return this.deleteMembership(userId, membershipId);
  }

  resendMembership(userId: string, membershipId: string) {
    return this.apiService.membershipResend({ userId, membershipId });
  }

  declineMembership(userId: string, membershipId: string) {
    return this.apiService.membershipsDecline({ userId, membershipId });
  }

  acceptMembership(userId: string, membershipId: string) {
    return this.apiService.membershipsAccept({ userId, membershipId });
  }
}
