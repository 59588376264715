import { Component, DestroyRef, HostListener, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import {
  AboutPanelComponent,
  AboutPanelInputs,
  AuthState,
  Environment as EnvironmentEnum,
  NavigationItem,
  PermissionKey,
  PermissionsState,
  SetEnvironment,
  SidePanel,
  SidepanelTypes,
  UserState,
} from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, filter, tap } from 'rxjs';

import { Store } from '@ngxs/store';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ClarityScriptLoaderService } from './services/clarity-script-loader.service';
import { LegalDocumentsService } from './services/legal-documents.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  tokenReady = false;
  userReady = false;
  noAuth?: boolean;
  isOnAdminSite = false;
  isOnLoggedOutPage = false;
  isOnHelpGuidePage = false;
  loggedOutPageReady = false;

  navItems: NavigationItem[] = [
    {
      label: 'UI-LIB.GENERAL.HOME',
      route: '/dashboard',
      icon: '16px_apps',
    },
  ];
  botItems: SidePanel[] = [];
  private permissions: PermissionKey[] = [];
  private mobileMetaTagSet?: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router,
    public title: Title,
    private store: Store,
    private cookieService: CookieService,
    private gtmService: GoogleTagManagerService,
    private clarityScriptLoader: ClarityScriptLoaderService,
    private meta: Meta,
    public legalDocumentsService: LegalDocumentsService,
    private destroyed: DestroyRef,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.noAuth = this.useNoAuth(event.url);
      this.isOnLoggedOutPage = event.url.includes('logged-out');
      this.isOnHelpGuidePage = event.url.includes('help-guide');

      if (event.url.includes('administration') && this.navItems.length === 1) {
        this.isOnAdminSite = true;
        this.navItems = this.addAdminItems();
      } else if (!event.url.includes('administration') && this.navItems.length > 1) {
        this.isOnAdminSite = false;
        this.navItems = [
          {
            label: 'UI-LIB.GENERAL.HOME',
            route: '/dashboard',
            icon: '16px_apps',
          },
        ];
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMetaTag();
  }

  useNoAuth(url: string) {
    return (
      url === '/about-gea' ||
      url === '/terms-and-conditions' ||
      url === '/cookie-list' ||
      url === '/data-privacy' ||
      url === '/logged-out'
    );
  }

  ngOnInit(): void {
    this.setMetaTag();
    this.setContentSecurityMetaTag();
    this.checkUserPermission();

    this.clarityScriptLoader.loadAndInitializeScript(
      this.environment?.clarity?.projectId,
      this.environment?.clarity?.enabled,
      this.environment?.clarity?.clarityLoaderLocation
    );
    this.cookieService.set('GEAIDOptionalCookie', 'this is an optional cookie');
    this.store
      // This is the official syntax of ngxs
      // eslint-disable-next-line @typescript-eslint/unbound-method
      .select(UserState.token)
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((token) => !!token),
        tap(() => {
          this.tokenReady = true;
        }),
        // This is the official syntax of ngxs
        // eslint-disable-next-line @typescript-eslint/unbound-method
        concatMap(() => this.store.select(UserState.user))
      )
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((user) => !!user.email)
      )
      .subscribe((user) => {
        const selectedLanguage = localStorage.getItem('language');
        if (user?.language) {
          this.translate.use(user.language);
        } else if (selectedLanguage) {
          this.translate.use(selectedLanguage);
        } else {
          this.translate.use(this.translate.defaultLang);
        }
        this.translate.get('GENERAL.TITLE').subscribe((appTitle: string) => {
          this.title.setTitle(appTitle);
        });
        this.userReady = true;
      });

    this.store
      .select(UserState.loggedOutPageReady)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((ready) => {
        this.loggedOutPageReady = !!ready;
      });
    this.store
      .select(UserState.authState)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((authState) => {
        if (authState === AuthState.LogoutRequested) {
          this.tokenReady = false;
          this.loggedOutPageReady = false;
        }
      });
    this.store.dispatch(new SetEnvironment(this.environment.name || EnvironmentEnum.PROD));
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyed)).subscribe(() => {
      this.botItems = [
        {
          key: 'contact',
          icon: '16px_chat',
          label: 'UI-LIB.NAV-BAR.CONTACT',
          type: SidepanelTypes.CONTACT,
        },
        {
          key: 'about',
          icon: '16px_c-info',
          label: 'UI-LIB.NAV-BAR.ABOUT',
          type: SidepanelTypes.FOOTER,
          ref: AboutPanelComponent,
          inputs: {
            copyright: 'GEA Group Services GmbH 2024',
            footers: [
              {
                key: 'imprint-header',
                header: 'UI-LIB.FOOTER.IMPRINT',
                content: [
                  {
                    key: 'imprint',
                    icon: '24px_pdf',
                    label: 'UI-LIB.FOOTER.IMPRINT',
                    action: () => this.legalDocumentsService.openImprint(),
                  },
                ],
              },
              {
                key: 'privacy-header',
                header: 'UI-LIB.FOOTER.DATAPRIVACY',
                content: [
                  {
                    key: 'privacy',
                    icon: '24px_pdf',
                    label: 'UI-LIB.FOOTER.DATAPRIVACY',
                    action: () => this.legalDocumentsService.openDataprivacy(),
                  },
                ],
              },
              {
                key: 'terms-header',
                header: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                content: [
                  {
                    key: 'terms',
                    icon: '24px_pdf',
                    label: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                    action: () => this.legalDocumentsService.openTermsAndConditions(),
                  },
                ],
              },
              {
                key: 'cookie-settings',
                header: 'UI-LIB.FOOTER.COOKIE-SETTINGS',
                content: [
                  {
                    key: 'cookies',
                    label: 'UI-LIB.FOOTER.COOKIES',
                    action: () => this.legalDocumentsService.openCookieSettings(),
                  },
                ],
              },
            ],
          } as AboutPanelInputs,
        },
      ];
    });
    this.translate.setDefaultLang('en-US');
    this.addCookielawScriptTag();
    void this.gtmService.addGtmToDom();
  }

  addCookielawScriptTag() {
    const cookielawId = this.environment.cookielawId;
    if (cookielawId) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', cookielawId);
      const head = document.head;
      const firstScriptTag = head.querySelector('script');
      if (firstScriptTag) {
        head.insertBefore(script, firstScriptTag);
      } else {
        head.appendChild(script);
      }
    }
  }

  addAdminItems(): NavigationItem[] {
    const homeItem = {
      label: 'UI-LIB.GENERAL.HOME',
      route: '/dashboard',
      icon: '16px_apps',
    };

    if (!this.isOnAdminSite) {
      return [homeItem];
    }

    return [
      homeItem,
      {
        label: 'NAVIGATION.GENERAL.ADMINISTRATION.PROFILE',
        route: '/administration/profile',
        icon: '16px_profile',
        disabled: !this.permissions.includes(PermissionKey.READ_OWN_PROFILE),
      },
      {
        label: 'X.LABEL.USERS',
        route: '/administration/user',
        icon: '16px_multiple',
        disabled: !this.permissions.includes(PermissionKey.READ_USER_LIST),
      },
      {
        label: 'X.LABEL.ORGANISATION',
        route: '/administration/organization',
        icon: '16px_filter-organization',
        disabled: !this.permissions.includes(PermissionKey.READ_ORGANIZATION_LIST),
      },
      {
        label: 'NAVIGATION.GENERAL.ADMINISTRATION.NOTIFICATION-SETTINGS',
        route: '/administration/notification-settings',
        icon: '16px_bell',
        disabled: !this.permissions.includes(PermissionKey.NOTIFICATION_SETTINGS_ACCESS),
      },
    ];
  }

  checkUserPermission() {
    this.store.select(PermissionsState.userPermissions).subscribe((permissions: PermissionKey[]) => {
      this.permissions = permissions;
      this.navItems = this.addAdminItems();
    });
  }

  setMetaTag() {
    if (screen.width < 767) {
      if (this.mobileMetaTagSet === undefined || !this.mobileMetaTagSet) {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=1000' });
        this.mobileMetaTagSet = true;
      }
    } else {
      if (this.mobileMetaTagSet === undefined || this.mobileMetaTagSet) {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
        this.mobileMetaTagSet = false;
      }
    }
  }

  private setContentSecurityMetaTag() {
    if (this.environment.name !== EnvironmentEnum.LOCAL) return;

    const clarityHash = "'sha256-eBrsYnxCaUBTeM1XIhPkiIoPd5K7IfZTSVprFYMvtc4='";
    const gtm_hash = "'sha256-smAb3YviQyMN5UZODsgCQGZEF5muenzX2n483kTSE7I='";
    const storageUrls: { [key: string]: string } = {
      dev: 'https://content.apps.dev.gea.com/',
      test: 'https://content.apps.tst.gea.com/',
      uat: 'https://content.apps.uat.gea.com/',
      prod: 'https://content.apps.gea.com/',
    };
    const stage = this.environment.name === EnvironmentEnum.LOCAL ? 'dev' : this.environment.name;
    const allowedScriptSources = `
    ${clarityHash} ${gtm_hash} 
      https://cdn.cookielaw.org https://www.googletagmanager.com https://www.google-analytics.com https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/legacy/build/pdf.worker.min.js 
      https://www.clarity.ms ${storageUrls[stage]} https://strgaccassets${stage}.blob.core.windows.net/strg-container-assets-${stage}/clarity-loader.js
    `;
    this.meta.addTag({
      'http-equiv': 'Content-Security-Policy',
      content: `script-src 'self' ${allowedScriptSources}; worker-src blob:;`,
    });
    this.meta.addTag({
      'http-equiv': 'Cache-control',
      content: `private, max-age=1800`,
    });
  }
}
