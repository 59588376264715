/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { SiteResponse } from './siteResponse';
import { OrganizationResponseLastVerificationRequester } from './organizationResponseLastVerificationRequester';
import { OrganizationType } from './organizationType';


export interface OrganizationResponse { 
    id: string;
    name: string;
    isBillingAddressSame: boolean;
    shippingAddress: Address;
    billingAddress?: Address;
    type: OrganizationType;
    sites: Array<SiteResponse>;
    customerNumber: string;
    verificationStatus: OrganizationResponseVerificationStatusEnum;
    lastVerificationRequest?: string;
    lastVerificationRequester?: OrganizationResponseLastVerificationRequester;
}
export enum OrganizationResponseVerificationStatusEnum {
    VERIFIED = 'VERIFIED',
    PENDING = 'PENDING',
    NOT_REQUESTED = 'NOT_REQUESTED'
};



