import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ApiErrorResponse, ErrorHandlerV2Service, SelectOption, SnackbarService } from '@gea/digital-ui-lib';
import { ComponentCanDeactivate } from '@gea-id/shared';
import { Observable } from 'rxjs';
import { UserNotificationsSettings, UserNotificationsSettingsApplication } from './models/user-notifications-settings.model';
import { UserNotificationSettingsApiService } from './services/user-notification-settings-api.service';

@Component({
  selector: 'gea-id-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrl: './notification-settings.component.scss',
})
export class NotificationSettingsComponent implements OnInit, ComponentCanDeactivate {
  apps: SelectOption[] = [];

  settings: UserNotificationsSettings = {} as UserNotificationsSettings;

  products: SelectOption[] = [];
  selectedProducts: SelectOption[] = [];

  loading = false;

  emailChannel = false;
  inAppChannel = false;
  selectedApp?: UserNotificationsSettingsApplication;
  allAppsToggleState = false;

  formGroup = this.formBuilder.group({
    product: new FormControl<SelectOption<string>[]>([]),
    apps: new FormControl<SelectOption<string> | null>(null),
  });

  constructor(
    private formBuilder: FormBuilder,
    private api: UserNotificationSettingsApiService,
    private errorhandlerService: ErrorHandlerV2Service,
    private snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.api.get().subscribe({
      next: (settings) => {
        this.loading = false;
        this.settings = settings;
        this.apps =
          this.settings.applications?.map((app) => ({
            nameKey: 'UI-LIB.NOTIFICATION-SETTINGS.APP.' + app.name,
            value: app.appId,
            disabled: app.notificationTypes.length === 0,
          })) ?? [];

        const tmpSelectedProducts: SelectOption[] = [];

        this.products =
          this.settings.products?.map((equipment) => {
            if (equipment.active) {
              tmpSelectedProducts.push({ name: equipment.name, value: equipment.id });
            }
            return { name: equipment.name, value: equipment.id };
          }) || [];
        this.emailChannel = this.settings?.channelEmail || false;
        this.inAppChannel = this.settings?.channelInApp || false;
        this.selectedProducts = tmpSelectedProducts;
      },
      error: (error: ApiErrorResponse) => {
        this.errorhandlerService.handleError(error);
        this.loading = false;
      },
    });
  }

  selectApp(appId: SelectOption<AppIdValue>) {
    this.selectedApp = this.settings?.applications?.find((app) => app.appId === appId.value.value);
    const systemMaintenance = this.selectedApp?.notificationTypes?.find(
      (notificationType) => notificationType.name === 'SYSTEM_MAINTENANCE'
    );
    if (systemMaintenance) {
      systemMaintenance.active = true;
      systemMaintenance.disabled = true;
    }
  }

  selectProduct(products: SelectOption<string>[]) {
    this.selectedProducts = products;
    this.settings?.products?.forEach((product) => {
      product.active = products.some((option) => option.value === product.id);
    });
  }

  clearProducts() {
    this.selectedProducts = [];
    this.settings?.products?.forEach((product) => {
      product.active = false;
    });
  }

  toggleAllApps() {
    this.settings?.applications?.forEach((app) => {
      app.notificationTypes?.forEach((notificationType) => {
        if (!notificationType.disabled) {
          notificationType.active = this.allAppsToggleState;
        }
      });
    });
  }

  setAllAppsToggleState() {
    if (this.selectedApp) {
      const enabledNotificationTypes = this.selectedApp.notificationTypes.filter((nt) => !nt.disabled);

      this.allAppsToggleState = enabledNotificationTypes.every((nt) => nt.active);
    }
  }

  save(): void {
    this.api.put(this.settings).subscribe({
      next: () => {
        this.snackbar.add({ severity: 'success', summary: 'X.MESSAGE.SUCCESS.SUMMARY', detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE' });
        this.formGroup.markAsPristine();
      },
      error: (e: ApiErrorResponse) => {
        this.errorhandlerService.handleError(e);
      },
    });
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formGroup.dirty;
  }

  toggleChannelEmail($event: boolean) {
    this.settings.channelEmail = $event;
    this.formGroup.markAsDirty();
  }

  toggleChannelInApp($event: boolean) {
    this.settings.channelInApp = $event;
    this.formGroup.markAsDirty();
  }
}

interface AppIdValue {
  name: string;
  nameKey: string;
  value: string;
  disabled: boolean;
}
