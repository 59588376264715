import { AddressModel } from '@gea/digital-ui-lib';

export interface OrgaData {
  address: AddressModel;
  orgaId: string;
  name: string;
  updatable: boolean;
  verificationRequestTime?: Date;
  verificationStatus: string;
  customerNumber?: string;
  type?: string;
  createdAt?: Date;
  lastModifiedAt?: Date;
  owner?: string;
}
