/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMembershipResponseOrganization } from './userMembershipResponseOrganization';
import { UserMembershipResponseRole } from './userMembershipResponseRole';
import { UserMembershipResponseId } from './userMembershipResponseId';
import { UserMembershipState } from './userMembershipState';


export interface UserMembershipResponse { 
    id: UserMembershipResponseId;
    organization: UserMembershipResponseOrganization;
    role: UserMembershipResponseRole;
    state: UserMembershipState;
    inherited: boolean;
    createdAt?: Date;
    modifiedAt?: Date;
}



