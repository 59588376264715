import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

import { GridService, LayoutService } from '@gea/digital-ui-lib';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'gea-id-workspace-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  tabItems: MenuItem[] = [
    { id: '1', label: 'DASHBOARD.DASHBOARD', routerLink: '/dashboard' },
    { id: '2', label: 'DASHBOARD.ALL-APPS', routerLink: '/all-apps' },
  ];
  loginDisplay = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public layout: LayoutService,
    public gridService: GridService
  ) {}

  isAboutGEARoute() {
    return (
      this.activatedRoute?.firstChild?.snapshot.url[0].path === 'about-gea' ||
      this.activatedRoute?.firstChild?.snapshot.url[0].path === 'terms-and-conditions' ||
      this.activatedRoute?.firstChild?.snapshot.url[0].path === 'cookie-list' ||
      this.activatedRoute?.firstChild?.snapshot.url[0].path === 'data-privacy'
    );
  }

  isDashboardRoute() {
    return this.activatedRoute?.firstChild?.snapshot.url[0].path === 'dashboard';
  }

  editDashboard() {
    this.gridService.editDashboard.next();
  }
}
