/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersList200ResponseOneOf } from './usersList200ResponseOneOf';
import { UserListAdminItemResponse } from './userListAdminItemResponse';
import { UsersList200ResponseOneOf1 } from './usersList200ResponseOneOf1';


/**
 * @type UsersList200Response
 * @export
 */
export type UsersList200Response = UsersList200ResponseOneOf | UsersList200ResponseOneOf1;

