/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerUserTypeDto } from './customerUserTypeDto';
import { UserMembershipResponse } from './userMembershipResponse';
import { UserResponseTimeZone } from './userResponseTimeZone';


export interface UserResponse { 
    id: string;
    customerUserType: CustomerUserTypeDto;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    title: string;
    language: string;
    country: string;
    /**
     * Only visible to gea admins
     */
    contactId?: string;
    timeZone?: UserResponseTimeZone;
    /**
     * whether the user opted in to MFA
     */
    optInMFA?: boolean;
    /**
     * whether MFA is enforced for the user
     */
    enforcedMFA?: boolean;
    memberships: Array<UserMembershipResponse>;
}



