import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService, ApiServiceInterface } from '@gea/digital-ui-lib';
import { UserNotificationsSettings } from '../models/user-notifications-settings.model';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationSettingsApiService implements ApiServiceInterface {
  url = 'notification-service/v1/notifications-settings';
  apiVersion = '1';

  constructor(private api: ApiService) {
    this.setUrl(this.url);
  }

  get(): Observable<UserNotificationsSettings> {
    return this.api.get<UserNotificationsSettings>(this.url);
  }

  put(userNotificationsSettings: UserNotificationsSettings): Observable<void> {
    return this.api.put<void>(this.url, userNotificationsSettings);
  }

  setUrl(url?: string): UserNotificationSettingsApiService {
    this.url = url ?? this.url;
    return this;
  }
  setApiVersion(apiVersion?: string): UserNotificationSettingsApiService {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }
}
