/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerUserTypeDto } from './customerUserTypeDto';
import { UserListItemState } from './userListItemState';


export interface UserPortalListItem { 
    id: string;
    customerUserType: CustomerUserTypeDto;
    firstName: string;
    lastName: string;
    email: string;
    numberOfUserMemberships?: number;
    numberOfInvitations?: number;
    userListItemState: UserListItemState;
}



