/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BackofficeMembershipHistoryResponse { 
    id: string;
    userId: string;
    organizationId: string;
    organizationName: string;
    roleId: string;
    roleName: string;
    language: string;
    appId: string;
    createdAt: Date;
}

