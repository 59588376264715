<gea-id-workspace-base-widget
  [backgroundColor]="widgetDisabled ? 'var(--geaui-grey-04)' : widgetStyle.backgroundColor"
  [color]="widgetDisabled ? 'var(--geaui-grey-01)' : color"
  [widgetDisabled]="widgetDisabled"
  [editModeActive]="editModeActive">
  <a [ngClass]="{ disabled: widgetDisabled }" href="{{ widgetUrl }}" target="_blank" rel="noopener">
    <span class="text" pTooltip="{{ 'APPS.' + appKey + '.WIDGETS.APP_LINK.NAME' | translate }}" geauiTooltipOnEllipsisV2>{{
      'APPS.' + appKey + '.WIDGETS.APP_LINK.NAME' | translate
    }}</span>
    <div class="icon-container icon-container-sw">
      <div
        class="icon-background"
        [ngStyle]="{
          background: this.widgetImagePath ? widgetStyle.iconBackgroundColor : widgetDefaultStyle.iconBackgroundColor
        }"></div>
      <img class="icon-svg" [src]="widgetImageUrl" [alt]="widgetImageUrl" />
      <div class="widget-background-container">
        <geaui-icon-v2
          class="widget-background"
          [baseUrl]="appContentUrl"
          [file]="iconName + '-background'"
          [color]="widgetDisabled ? 'grey' : 'white'"></geaui-icon-v2>
      </div>
    </div>
  </a>
</gea-id-workspace-base-widget>
