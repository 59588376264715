import { ChangeDetectorRef, Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  DialogV2Service,
  PatchUser,
  PermissionKey,
  PermissionsState,
  ResourcesSelectOptionsModel,
  SelectOption,
  SnackbarService,
  User,
} from '@gea/digital-ui-lib';
import { UserDetailService, FormService, CultureService, CustomerUserTypeDto } from '@gea-id/shared';
import { combineLatest, filter, Observable, startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gea-id-edit-user-form',
  templateUrl: './portal-edit-user-form.component.html',
  styleUrl: './portal-edit-user-form.component.scss',
})
export class PortalEditUserFormComponent implements OnInit, OnDestroy {
  protected userId = '';
  protected profileData: User | null = null;
  protected isDisabled = true;
  protected isSaveButtonDisabledByForm = false;
  protected isSending = false;
  protected loading = false;
  protected languageOptions: SelectOption<string>[] = [];
  protected titleOptionsAll: Record<string, string[]> = {};
  protected titleOptions: SelectOption<string>[] = [];
  protected timeZoneOptions: SelectOption<string>[] = [];
  protected countryOptions: SelectOption<string>[] = [];
  protected readonly customerTypeOptions: SelectOption<string>[] = Object.keys(CustomerUserTypeDto).map((key) => ({
    value: key,
    nameKey: `X.CUSTOMER_USER_TYPE.${key}`,
  }));

  protected readonly FORM_CONTROL_TITLE = 'title';
  protected readonly FORM_CONTROL_CUSTOMER_TYPE = 'customerUserType';
  protected readonly FORM_CONTROL_FIRSTNAME = 'firstName';
  protected readonly FORM_CONTROL_LASTNAME = 'lastName';
  protected readonly FORM_CONTROL_EMAIL = 'email';
  protected readonly FORM_CONTROL_PHONE = 'phone';
  protected readonly FORM_CONTROL_LANGUAGE = 'language';
  protected readonly FORM_CONTROL_COUNTRY = 'country';
  protected readonly FORM_CONTROL_OPT_MFA = 'optInMFA';
  protected readonly FORM_CONTROL_TIMEZONE = 'timezone';
  protected readonly FORM_CONTROL_ENFORCE_MFA = 'enforcedMFA';
  protected hasFullName = false;

  private readonly nameRegex = /^([^0-9]*)$/;
  private readonly USER_LIST_PATH = 'user';

  form = this.formBuilder.group({
    [this.FORM_CONTROL_TITLE]: ['', []],
    [this.FORM_CONTROL_CUSTOMER_TYPE]: [{ value: CustomerUserTypeDto.BASIC, disabled: true }, [Validators.required]],
    [this.FORM_CONTROL_FIRSTNAME]: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.nameRegex)]],
    [this.FORM_CONTROL_LASTNAME]: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.nameRegex)]],
    [this.FORM_CONTROL_EMAIL]: ['', [Validators.required, Validators.email, Validators.maxLength(80)]],
    [this.FORM_CONTROL_PHONE]: ['', [Validators.maxLength(64)]],
    [this.FORM_CONTROL_LANGUAGE]: ['', [Validators.required]],
    [this.FORM_CONTROL_COUNTRY]: ['', []],
    [this.FORM_CONTROL_ENFORCE_MFA]: [false],
    [this.FORM_CONTROL_TIMEZONE]: ['', [Validators.required]],
    [this.FORM_CONTROL_OPT_MFA]: [false],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userDetailService: UserDetailService,
    private snackBarService: SnackbarService,
    private formCacheService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogV2Service,
    private store: Store,
    private destroyRef: DestroyRef,
    private cultureService: CultureService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userId = this.userDetailService.currentUserId;
    if (this.formCacheService.isFormCached()) this.initializeFormDataFromCache();
    else this.loadFormData();
  }

  ngOnDestroy() {
    if (this.profileData) {
      this.formCacheService.cacheForm(this.form);
    }
  }

  onLocaleChanged(): void {
    const selectedLanguage = (this.form.get(this.FORM_CONTROL_LANGUAGE)?.value as string) ?? undefined;
    const selectedTitle = this.form.get(this.FORM_CONTROL_TITLE)?.value ?? undefined;
    const index = this.titleOptions.findIndex((option) => option.value === selectedTitle);
    if (this.titleOptionsAll) {
      this.titleOptions = this.getTitleOptionsForLang(selectedLanguage);
      if (index !== -1) {
        const newTitle = this.titleOptions[index];
        this.form.patchValue({ title: newTitle?.value });
      }
    }
  }

  saveProfileUser() {
    if (this.form.invalid) return;
    this.isSending = true;
    const userData = this.buildUpdateProfileData();
    this.userDetailService.updateProfileData(this.userId, userData).subscribe({
      next: () => this.handleSuccessSaveUser(),
      error: () => (this.isSending = false),
    });
  }

  showRemoveAccountDialog() {
    this.dialogService.open({
      title: 'X.PROMPT.DELETE_CONFIRM.DETAIL',
      message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      confirmCallback: () => this.removeUserAccount(),
    });
  }

  get hasNoUpdateUserPermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return !permissions.includes(PermissionKey.UPDATE_USER);
      })
    );
  }

  get deleteButtonVisibility(): Observable<boolean> {
    return this.store
      .select(PermissionsState.userPermissions)
      .pipe(map((permissions) => permissions.includes(PermissionKey.DELETE_USER)));
  }

  private removeUserAccount() {
    const email = this.form.get(this.FORM_CONTROL_EMAIL)?.value as string;
    this.loading = true;
    this.userDetailService.deleteUser(email).subscribe({
      next: () => this.handleSuccessRemoveUserAccount(),
      error: () => (this.loading = false),
    });
  }

  private initializeFormDataFromCache() {
    this.form = this.formCacheService.readFormCache() as typeof this.form;
    this.hasFullName = !!this.form.value.firstName && !!this.form.value.lastName;
    const resourcesData = this.formCacheService.readResourceCache();
    this.setResourcesData(resourcesData);
    this.onLocaleChanged();
    this.registerFormValueChanges();
  }

  private loadFormData() {
    this.loading = true;
    combineLatest([this.userDetailService.profileFormData$, this.cultureService.resourceData$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(([userData, resourceData]) => !!userData && !!resourceData)
      )
      .subscribe(([userData, resourceData]) => {
        this.profileData = userData;
        this.hasFullName = !!this.profileData?.firstName && !!this.profileData?.lastName;
        this.setResourcesData(resourceData);
        this.fillFormInputs();
        this.setDisabledState();
        this.registerFormValueChanges();
        this.loading = false;
      });
  }

  private registerFormValueChanges() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.form.dirty) this.formCacheService.notifyFormChanges(true);
      this.isSaveButtonDisabledByForm = !this.form.valid;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setResourcesData(resourcesModel: ResourcesSelectOptionsModel | null) {
    if (resourcesModel) {
      this.titleOptionsAll = resourcesModel.titleOptionsAll ?? this.titleOptionsAll;
      this.countryOptions = resourcesModel.countryOptions ?? [];
      this.languageOptions =
        resourcesModel.languageOptions?.map((option) => ({
          ...option,
          nameKey: 'X.LANGUAGE.' + option.value.toUpperCase(),
        })) ?? [];
      this.timeZoneOptions = resourcesModel.timeZoneOptions ?? [];
    }
  }

  private fillFormInputs() {
    this.form.patchValue({
      customerUserType: this.profileData?.customerUserType,
      firstName: this.profileData?.firstName,
      lastName: this.profileData?.lastName,
      email: this.profileData?.email,
      phone: this.profileData?.phoneNumber,
      enforcedMFA: !!this.profileData?.enforcedMFA,
      optInMFA: !!this.profileData?.optInMFA || !!this.profileData?.enforcedMFA,
      title: this.findSelectedTitleOption(this.profileData?.language ?? '')?.value ?? '',
      language: this.findSelectedLanguageOption()?.value ?? '',
      country: this.findSelectedCountryOption()?.value ?? '',
      timezone: this.findSelectedTimezoneOption()?.value ?? '',
    });
  }

  private findSelectedTitleOption(lang: string) {
    if (lang === this.profileData?.language) {
      return this.getTitleOptionsForLang(lang).find((option) => option.value === this.profileData?.title);
    }
    return null;
  }

  private getTitleOptionsForLang(lang: string) {
    return this.titleOptionsAll[lang]?.map((title) => ({ name: title, value: title })) ?? [];
  }

  private findSelectedLanguageOption() {
    const lang = this.profileData?.language;
    return this.languageOptions.find((option) => option.value === lang);
  }

  private findSelectedCountryOption() {
    const country = this.profileData?.country;
    const countryOptionExists = this.countryOptions?.some((option) => option.value === country);
    if (!countryOptionExists) {
      return;
    }
    return this.countryOptions.find((option) => option.value === country);
  }

  private findSelectedTimezoneOption() {
    const timezone = this.profileData?.timeZone?.zoneId;
    const timezoneOptionExists = this.timeZoneOptions?.some((option) => option.value === timezone);
    if (!timezoneOptionExists) {
      return;
    }
    return this.timeZoneOptions.find((option) => option.value === timezone);
  }

  private handleSuccessSaveUser() {
    this.formCacheService.notifyFormChanges(false);
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
    this.isSending = false;
  }

  private buildUpdateProfileData(): PatchUser {
    return {
      customerUserType: this.form.value.customerUserType,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      phoneNumber: this.form.value.phone,
      language: this.form.value.language,
      timeZone: {
        zoneId: this.form.value.timezone,
      },
      title: this.form.value.title,
      country: this.form.value.country,
    } as PatchUser;
  }

  private setDisabledState() {
    if (this.isDisabled) {
      this.form.get(this.FORM_CONTROL_TITLE)?.disable();
      this.form.get(this.FORM_CONTROL_FIRSTNAME)?.disable();
      this.form.get(this.FORM_CONTROL_LASTNAME)?.disable();
      this.form.get(this.FORM_CONTROL_EMAIL)?.disable();
      this.form.get(this.FORM_CONTROL_PHONE)?.disable();
      this.form.get(this.FORM_CONTROL_LANGUAGE)?.disable();
      this.form.get(this.FORM_CONTROL_COUNTRY)?.disable();
      this.form.get(this.FORM_CONTROL_TIMEZONE)?.disable();
      this.form.get(this.FORM_CONTROL_OPT_MFA)?.disable();
      this.form.get(this.FORM_CONTROL_ENFORCE_MFA)?.disable();
    }
    if (this.profileData?.enforcedMFA) this.form.get(this.FORM_CONTROL_ENFORCE_MFA)?.disable();
  }

  private handleSuccessRemoveUserAccount() {
    this.loading = false;
    void void this.router.navigate([this.USER_LIST_PATH]);
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
      severity: 'success',
    });
  }
}
