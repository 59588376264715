import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  ApiErrorResponse,
  DialogV2Service,
  ErrorHandlerV2Service,
  LoginService,
  PatchUser,
  SelectOption,
  SnackbarService,
  UpdateGeaIdUser,
  User,
  UserState,
} from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';
import { filter, first, forkJoin, map, mergeMap, tap } from 'rxjs';
import { CultureService, CustomerUserTypeDto, UserService } from '@gea-id/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-id-workspace-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss',
})
export class PersonalDataComponent implements OnInit {
  loading = false;
  isSending = false;
  isSaveButtonDisabledByForm = false;
  titleOptions: SelectOption<string>[] = [];
  languageOptions: SelectOption<string>[] = [];
  countryOptions: SelectOption<string>[] = [];
  timeZoneOptions: SelectOption<string>[] = [];
  profileData?: User;
  titleOptionsAll?: Record<string, string[]>;
  userId?: string;

  protected readonly customerTypeOptions: SelectOption<string>[] = [CustomerUserTypeDto.BASIC, CustomerUserTypeDto.SHARED].map(
    (key) => ({
      value: key,
      nameKey: `X.CUSTOMER_USER_TYPE.${key}`,
    })
  );

  protected readonly FORM_CONTROL_TITLE = 'title';
  protected readonly FORM_CONTROL_CUSTOMER_TYPE = 'customerUserType';
  protected readonly FORM_CONTROL_FIRSTNAME = 'firstName';
  protected readonly FORM_CONTROL_LASTNAME = 'lastName';
  protected readonly FORM_CONTROL_EMAIL = 'email';
  protected readonly FORM_CONTROL_PHONE = 'phone';
  protected readonly FORM_CONTROL_LANGUAGE = 'language';
  protected readonly FORM_CONTROL_COUNTRY = 'country';
  protected readonly FORM_CONTROL_OPT_MFA = 'optInMFA';
  protected readonly FORM_CONTROL_CONTACT_ID = 'contactId';
  protected readonly FORM_CONTROL_TIMEZONE = 'timezone';
  protected readonly FORM_CONTROL_ENFORCE_MFA = 'enforcedMFA';

  private readonly nameRegex = /^([^0-9]*)$/;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogV2Service,
    private userService: UserService,
    private store: Store,
    private cultureService: CultureService,
    private errorHandler: ErrorHandlerV2Service,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private destroyRef: DestroyRef
  ) {}

  form = this.formBuilder.group({
    [this.FORM_CONTROL_TITLE]: new FormControl<string | null>(null),
    [this.FORM_CONTROL_CUSTOMER_TYPE]: ['' as CustomerUserTypeDto, [Validators.required]],
    [this.FORM_CONTROL_FIRSTNAME]: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.nameRegex)]],
    [this.FORM_CONTROL_LASTNAME]: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.nameRegex)]],
    [this.FORM_CONTROL_EMAIL]: ['', [Validators.required, Validators.email, Validators.maxLength(80)]],
    [this.FORM_CONTROL_PHONE]: ['', [Validators.maxLength(64)]],
    [this.FORM_CONTROL_LANGUAGE]: new FormControl<string | null>(null, [Validators.required]),
    [this.FORM_CONTROL_COUNTRY]: new FormControl<string | null>({ value: null, disabled: true }),
    [this.FORM_CONTROL_ENFORCE_MFA]: [false],
    [this.FORM_CONTROL_TIMEZONE]: new FormControl<string | null>(null, [Validators.required]),
    [this.FORM_CONTROL_CONTACT_ID]: [''],
    [this.FORM_CONTROL_OPT_MFA]: [false],
  });

  ngOnInit() {
    this.cultureService.loadResourcesData();
    this.loading = true;
    const fetchUser$ = this.store.select(UserState.user).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((user) => user.userId ?? ''),
      filter((userId) => !!userId),
      tap((userId) => (this.userId = userId)),
      mergeMap((userId) => this.userService.getUser(userId)),
      first()
    );

    const fetchResources$ = this.cultureService.resourceData$.pipe(
      filter((resources) => !!resources),
      first()
    );

    forkJoin([fetchUser$, fetchResources$]).subscribe({
      next: ([user, resources]) => {
        this.profileData = user;
        if (resources) {
          this.languageOptions =
            resources.languageOptions?.map((option) => ({
              ...option,
              nameKey: 'X.LANGUAGE.' + option.value.toUpperCase(),
            })) ?? [];
          this.countryOptions = resources.countryOptions ?? [];
          this.timeZoneOptions = resources.timeZoneOptions ?? [];
          if (resources.titleOptionsAll) {
            const lang = user.language ?? 'en';
            this.titleOptionsAll = resources.titleOptionsAll ?? [];
            this.titleOptions =
              resources.titleOptionsAll[lang]?.map((title) => ({
                name: title,
                value: title,
              })) ?? [];
          }
        }
        this.updateControls();
        this.loading = false;
        this.store.dispatch(new UpdateGeaIdUser(user.language, user.country, user.email, user.firstName, user.lastName));
      },
      error: (error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }

  onLocaleChange(): void {
    const selectedLanguage = this.form.get(this.FORM_CONTROL_LANGUAGE)?.value as string;
    const selectedTitle = this.form.get(this.FORM_CONTROL_TITLE)?.value as string;
    const index = this.titleOptions.findIndex((option) => option.value === selectedTitle);
    if (this.titleOptionsAll) {
      this.titleOptions =
        this.titleOptionsAll[selectedLanguage]?.map((title) => ({
          name: title,
          value: title,
        })) ?? [];
      if (index !== -1) {
        const newTitle = this.titleOptions[index];
        this.form.patchValue({ title: newTitle?.value });
      }
    }
  }

  showRemoveAccountDialog(): void {
    this.dialogService.open({
      title: 'PROFILE.FORM.DELETE_USER_DIALOG.TITLE',
      message: 'PROFILE.FORM.DELETE_USER_DIALOG.MESSAGE',
      yes: 'X.BUTTON.DELETE',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => this.removeUserAccount(),
    });
  }

  saveProfileUser(): void {
    if (this.form.invalid || !this.userId) return;
    this.isSending = true;
    const userData = this.buildUpdateProfileData();
    this.userService.updateUser(this.userId, userData).subscribe({
      next: () => this.handleSuccessSaveUser(),
      error: (error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        this.isSending = false;
      },
    });
  }

  canDeactivate(): boolean {
    return !this.form.dirty;
  }

  private buildUpdateProfileData(): PatchUser {
    return {
      customerUserType: this.form.value.customerUserType,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      phoneNumber: this.form.value.phone,
      language: this.form.value.language,
      timeZone: {
        zoneId: this.form.value.timezone,
      },
      title: this.form.value.title,
      contactId: this.form.value.contactId,
      country: this.form.value.country,
    } as PatchUser;
  }

  private removeUserAccount(): void {
    this.userService
      .deleteSelf()
      .pipe(first())
      .subscribe({
        next: () => {
          this.loginService.logout();
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
        },
      });
  }

  private handleSuccessSaveUser() {
    this.snackbarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
    this.isSending = false;
    this.form.markAsPristine();
    const selectedLanguage = this.form.get(this.FORM_CONTROL_LANGUAGE)?.value as string;
    this.translateService.use(selectedLanguage);
    this.userService.getUser(this.userId ?? '').subscribe({
      next: (user) =>
        this.store.dispatch(new UpdateGeaIdUser(user.language, user.country, user.email, user.firstName, user.lastName)),
    });
  }

  private updateControls() {
    this.form.patchValue({
      customerUserType: this.profileData?.customerUserType,
      firstName: this.profileData?.firstName,
      lastName: this.profileData?.lastName,
      email: this.profileData?.email,
      phone: this.profileData?.phoneNumber,
      contactId: this.profileData?.contactId,
      enforcedMFA: !!this.profileData?.enforcedMFA,
      optInMFA: !!this.profileData?.optInMFA || !!this.profileData?.enforcedMFA,
      title: this.findSelectedTitleOption()?.value ?? '',
      language: this.findSelectedLanguageOption()?.value ?? '',
      country: this.findSelectedCountryOption()?.value ?? '',
      timezone: this.findSelectedTimezoneOption()?.value ?? '',
    });

    if ((this.profileData?.customerUserType as CustomerUserTypeDto) === CustomerUserTypeDto.TEST) {
      this.form.get(this.FORM_CONTROL_CUSTOMER_TYPE)?.disable();
      this.customerTypeOptions.push({
        value: CustomerUserTypeDto.TEST,
        nameKey: `X.CUSTOMER_USER_TYPE.${CustomerUserTypeDto.TEST}`,
      });
    }
  }

  private findSelectedTitleOption(): SelectOption<string> | null {
    return this.titleOptions.find((option) => option.value === this.profileData?.title) ?? null;
  }

  private findSelectedLanguageOption(): SelectOption<string> | null {
    return this.languageOptions.find((option) => option.value === this.profileData?.language) ?? null;
  }

  private findSelectedCountryOption(): SelectOption<string> | null {
    return this.countryOptions.find((option) => option.value === this.profileData?.country) ?? null;
  }

  private findSelectedTimezoneOption(): SelectOption<string> | null {
    return this.timeZoneOptions.find((option) => option.value === this.profileData?.timeZone.zoneId) ?? null;
  }
}
