import { Injectable } from '@angular/core';

import { App, AppService } from '@gea/digital-ui-lib';
import { map, Observable, take } from 'rxjs';

import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class AppAndIconService {
  constructor(
    protected store: Store,
    private appService: AppService
  ) {}

  getAppsAndInitIcons(): Observable<{ appList: App[]; verifyAppList: App[] }> {
    return this.appService.getAllApps().pipe(
      take(1),
      map((apps: App[]) => {
        this.store.dispatch({ type: 'APP_LIST', payload: apps });
        return {
          appList: apps.filter((app) => !app.locked),
          verifyAppList: apps.filter((app) => app.locked),
        };
      })
    );
  }
}
