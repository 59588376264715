<form [formGroup]="form">
  <div class="geaui-sub-grid modal">
    <geaui-input-v2
      class="geaui-row-desktop-md-start-1 geaui-col-desktop-md-start-1 geaui-col-desktop-md-6"
      [required]="true"
      [loading]="loading"
      [group]="form"
      formName="siteName"
      label="X.LABEL.NAME">
    </geaui-input-v2>
    <geaui-input-v2
      class="geaui-row-desktop-md-start-1 geaui-col-desktop-md-start-7 geaui-col-desktop-md-6"
      [required]="true"
      [loading]="loading"
      [group]="form"
      formName="street"
      label="X.LABEL.STREET">
    </geaui-input-v2>
    <geaui-input-v2
      class="geaui-row-desktop-md-start-2 geaui-col-desktop-md-start-1 geaui-col-desktop-md-6"
      [required]="true"
      [loading]="loading"
      [group]="form"
      formName="city"
      label="X.LABEL.CITY">
    </geaui-input-v2>
    <geaui-input-v2
      class="geaui-row-desktop-md-start-2 geaui-col-desktop-md-start-7 geaui-col-desktop-md-6"
      [required]="true"
      [loading]="loading"
      [group]="form"
      formName="zipCode"
      label="X.LABEL.ZIP">
    </geaui-input-v2>
    <geaui-autocomplete-select-v2
      class="geaui-row-desktop-md-start-3 geaui-col-desktop-md-start-1 geaui-col-desktop-md-6"
      [loading]="loading"
      [options]="(countries$ | async) ?? []"
      [formControl]="form.controls.country"
      label="X.LABEL.COUNTRY"
      formControlName="country">
    </geaui-autocomplete-select-v2>
    <geaui-input-v2
      class="geaui-row-desktop-md-start-3 geaui-col-desktop-md-start-7 geaui-col-desktop-md-6"
      [required]="false"
      [loading]="loading"
      [group]="form"
      formName="phoneNumber"
      label="X.LABEL.PHONE">
      >
    </geaui-input-v2>

    <!-- ADD DELETE BUTTON -->
    @if (isEditing) {
      <geaui-link-v2
        class="delete-button geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-4"
        [loading]="loading"
        (click)="deleteSite()"
        preIcon="16px_trash-can"
        type="cancel-red">
        {{ 'X.BUTTON.DELETE' | translate }}
      </geaui-link-v2>
    }
  </div>
</form>
