/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { OrganizationType } from './organizationType';


export interface OrganizationAdminListItemResponse { 
    orgaId: string;
    name: string;
    address: Address;
    updatable: boolean;
    type?: OrganizationType;
    verificationStatus: string;
    verificationRequestTime?: Date;
    customerNumber?: string;
    owner?: string;
    userCount?: number;
    createdAt?: Date;
    lastModifiedAt?: Date;
}



