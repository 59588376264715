import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

import { AboutGeaComponent } from './about-gea/about-gea.component';
import { AllAppsComponent } from './home/all-apps/all-apps.component';
import { CookieListComponent } from './data-privacy/cookie-list/cookie-list.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { HelpGuideComponent } from "./help-guide/help-guide.component";

const routes: Routes = [
  {
    // Needed for hash routing
    path: 'error',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'state',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'code',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'about-gea',
    component: AboutGeaComponent,
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
    canActivate: [],
  },
  {
    path: 'help-guide',
    component: HelpGuideComponent,
    canActivate: [],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'all-apps',
    component: AllAppsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'data-privacy',
    component: DataPrivacyComponent,
  },
  {
    path: 'cookie-list',
    component: CookieListComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./administration/portal-administration-routing.module').then((m) => m.PortalAdministrationRoutingModule),
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
