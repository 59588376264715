import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  ErrorHandlerV2Service,
  Membership,
  MembershipState,
  PermissionKey,
  SelectOption,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  CultureService,
  MembershipService,
  PermissionsApiService,
  OrgaData,
  OWNER_ROLE_ID,
  RoleWithOrgaType,
  UserDetailService,
  MembershipResponsePermissionsEnum,
} from '@gea-id/shared';
import { filter, first, map, Observable, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gea-id-workspace-membership-dialog',
  templateUrl: './portal-membership-dialog.component.html',
  styleUrl: './portal-membership-dialog.component.scss',
})
export class PortalMembershipDialogComponent implements ComplexDialogEmbeddedView, OnInit {
  protected readonly FORM_CONTROL_ORGANISATION = 'organisation';
  protected readonly FORM_CONTROL_ROLE = 'role';
  protected readonly MembershipState = MembershipState;
  isAcceptDisabled = true;
  organisationOptions: SelectOption<OrgaData>[] = [];

  form = this.formBuilder.group({
    [this.FORM_CONTROL_ORGANISATION]: new FormControl<OrgaData | null>(null, [Validators.required]),
    [this.FORM_CONTROL_ROLE]: new FormControl<RoleWithOrgaType | null>(null, [Validators.required]),
  });
  updatePermission = false;
  loading = true;
  invitationUpdating = false;

  // FIXME: Owner role is hardcoded because it's currently the only role that causes this problem. Once more roles causes problems, we need a proper solution.
  private OWNER_ROLE: RoleWithOrgaType = { name: 'OWNER', id: OWNER_ROLE_ID, enabledOrgaTypes: [] };

  constructor(
    protected translateService: TranslateService,
    private complexDialogService: ComplexDialogV2Service,
    private formBuilder: FormBuilder,
    private userDetailService: UserDetailService,
    private membershipService: MembershipService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackBarService: SnackbarService,
    private cultureService: CultureService,
    private dialogService: DialogV2Service,
    private destroyRef: DestroyRef,
    private permissionsApiService: PermissionsApiService,
    private router: Router,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) public membershipData?: Membership
  ) {}

  ngOnInit(): void {
    this.isAcceptDisabled = !!this.membershipData;
    this.cultureService.isOrganizationLoaded$
      .pipe(
        filter((isLoaded) => isLoaded),
        tap(() => (this.loading = false)),
        first()
      )
      .subscribe(() => this.getOrganisationAndRoles());

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isAcceptDisabled = this.form.invalid || !!this.membershipData;
    });

    if (this.membershipData) {
      this.invitationUpdating = true;
      this.permissionsApiService
        .permissionsListOrganization({ orgaId: this.membershipData.organizationId })
        .pipe(
          map((permissions) => permissions?.includes(PermissionKey.UPDATE_MEMBERSHIP)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((updatePermission) => {
          this.updatePermission = updatePermission ?? false;
          this.invitationUpdating = false;
        });
    }
  }

  onResendInvitation() {
    if (!this.updatePermission) {
      return;
    }
    this.invitationUpdating = true;
    this.membershipService.resendMembership(this.membershipData?.userId ?? '', this.membershipData?.id ?? '').subscribe({
      next: () => this.handleResendInvitationSuccess(),
      error: (error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        this.complexDialogService.close();
      },
    });
  }

  onCancelInvitation() {
    if (!this.updatePermission) {
      return;
    }
    this.invitationUpdating = true;
    this.membershipService.revokeMembership(this.membershipData?.userId ?? '', this.membershipData?.id ?? '').subscribe({
      next: () => this.handleCancelInvitationSuccess(),
      error: (error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        this.invitationUpdating = false;
      },
    });
  }

  showRemoveMembershipDialog() {
    this.invitationUpdating = true;
    this.dialogService.open({
      title: 'X.LABEL.DELETE',
      message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => this.removeMembership(),
    });
  }

  onAcceptClick() {
    this.complexDialogService.emitDataOutputForComponent({
      membershipID: this.membershipData?.id ?? '',
      organisation: this.form.value.organisation as OrgaData,
      role: this.form.value.role as RoleWithOrgaType,
    });
    this.complexDialogService.close();
  }

  get membershipStateTranslateKey(): string {
    return 'MEMBERSHIPS.LIST.STATE.' + this.membershipData?.state?.toString() ?? '';
  }

  private getOrganisationAndRoles(): void {
    const membershipsOfLoggedInUser: Membership[] = this.cultureService.memberships ?? [];
    this.fillOrganizationsOptions(this.cultureService.organizations, membershipsOfLoggedInUser);
    this.fillFormFields();
  }

  private fillOrganizationsOptions(organizations: OrgaData[], membershipsOfLoggedInUser: Membership[]): void {
    this.organisationOptions = organizations
      .filter((orga) => {
        const membership: Membership | undefined = membershipsOfLoggedInUser.find(
          (membership) => orga.orgaId === membership.organizationId
        );
        const isAccepted = membership?.state === MembershipState.ACCEPTED || membership?.state === MembershipState.INHERITED;

        const permissions: MembershipResponsePermissionsEnum[] | undefined =
          membership?.permissions as MembershipResponsePermissionsEnum[];
        const hasInvitePermission = permissions?.includes(MembershipResponsePermissionsEnum.CREATE_USER_INVITATION) ?? false;
        return isAccepted && hasInvitePermission;
      })
      .map((organization) => {
        return {
          name: organization.name,
          value: organization,
        } as SelectOption<OrgaData>;
      });
  }

  get roleOptions$(): Observable<SelectOption<RoleWithOrgaType>[]> {
    let orga = this.form.get(this.FORM_CONTROL_ORGANISATION)?.value;
    if (typeof orga === 'string') orga = this.organisationOptions.find((o) => o.name === orga)?.value;

    return this.userDetailService.memberships$.pipe(
      map((membershipsListResponse) => membershipsListResponse?.pageEntries ?? []),
      map((memberships) => {
        return this.cultureService.roles
          .filter((role) => role.enabledOrgaTypes.includes(orga?.type ?? ''))
          .filter(
            (role) =>
              !memberships
                .filter((membership) => orga?.orgaId === membership.organizationId)
                .map((membership) => membership.roleId)
                .includes(role.id) || role.id === this.membershipData?.roleId
          );
      }),
      map((roles) => {
        if (this.membershipData) {
          roles.push(this.OWNER_ROLE);
        }
        return roles.map((role) => {
          return {
            name: this.translateService.instant('X.ROLE.' + (role.name || '').toUpperCase()) as string,
            value: role,
          } as SelectOption<RoleWithOrgaType>;
        });
      })
    );
  }

  private fillFormFields() {
    if (this.membershipData) {
      const role = [...this.cultureService.roles, this.OWNER_ROLE].find((roleOpt) => roleOpt.id === this.membershipData?.roleId);
      const organisation =
        this.organisationOptions.find((orgaOpt) => orgaOpt.value.orgaId === this.membershipData?.organizationId)?.value ?? null;
      this.form.patchValue({ organisation, role });
      this.form.get(this.FORM_CONTROL_ROLE)?.disable();
      this.form.get(this.FORM_CONTROL_ORGANISATION)?.disable();
    }
  }

  private removeMembership() {
    this.membershipService.deleteMembership(this.membershipData?.userId ?? '', this.membershipData?.id ?? '').subscribe({
      next: () => this.handleMembershipDeleteSuccess(),
      error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
    });
  }

  private handleResendInvitationSuccess() {
    this.userDetailService.resetExpirationOfMembership(this.membershipData?.id);
    this.complexDialogService.close();
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private handleCancelInvitationSuccess() {
    this.handleMembershipRemoval();
    this.complexDialogService.close();
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private handleMembershipDeleteSuccess() {
    this.handleMembershipRemoval();
    this.complexDialogService.close();
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
      severity: 'success',
    });
  }

  private handleMembershipRemoval() {
    if (this.userDetailService.memberships?.filter((mem) => !mem.inherited).length === 1) {
      this.userDetailService.currentUserId = '';
      this.userDetailService.resetMemberships();
      void this.router.navigate(['administration', 'user']);
    } else {
      this.userDetailService.fetchMemberships();
    }
  }

  getIconFile(): string {
    switch (this.membershipData?.state) {
      case MembershipState.ACCEPTED || MembershipState.INHERITED: {
        return '16px_check';
      }
      case MembershipState.EXPIRED: {
        return '16px_stop';
      }
      default: {
        return '16px_c-info';
      }
    }
  }
}
