/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationAdminResponseAllOfLastVerificationRequester } from './organizationAdminResponseAllOfLastVerificationRequester';
import { Address } from './address';
import { SiteRequest } from './siteRequest';


export interface OrganizationAdminResponse { 
    id: string;
    name: string;
    isBillingAddressSame: boolean;
    shippingAddress: Address;
    billingAddress?: Address;
    type: string;
    sites: Array<SiteRequest>;
    customerNumber: string;
    verificationStatus: string;
    lastVerificationRequest?: Date;
    lastVerificationRequester?: OrganizationAdminResponseAllOfLastVerificationRequester;
}

