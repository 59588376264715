/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerUserTypeDto } from './customerUserTypeDto';
import { UserPatchRequestTimezone } from './userPatchRequestTimezone';


export interface UserPatchRequest { 
    customerUserType?: CustomerUserTypeDto;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    language?: string;
    country?: string;
    timezone?: UserPatchRequestTimezone;
    title?: string;
    /**
     * Only visible to gea admins
     */
    contactId?: string;
    /**
     * whether MFA is enforced for the user
     */
    enforceMFA?: boolean;
    /**
     * whether the user opted in to MFA
     */
    optInMFA?: boolean;
}



