/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InfoResponseGit } from './infoResponseGit';
import { InfoResponseBuild } from './infoResponseBuild';
import { InfoResponseDatabaseSchema } from './infoResponseDatabaseSchema';


export interface InfoResponse { 
    build?: InfoResponseBuild;
    databaseSchema?: InfoResponseDatabaseSchema;
    git?: InfoResponseGit;
}

