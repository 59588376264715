/**
 * Digital Platform - GEA ID
 * This is the API for the GEA ID. It is used to manage users, memberships, organization, roles and more.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum OrganizationType {
    UNKNOWN = 'UNKNOWN',
    DAIRYPROCESSER = 'DAIRYPROCESSER',
    FT_FARMER = 'FT_FARMER',
    FT_DEALER = 'FT_DEALER',
    FOOD = 'FOOD',
    GEA_INTERNAL_ORGA = 'GEA_INTERNAL_ORGA',
    BREWERY = 'BREWERY',
    CHEMICAL = 'CHEMICAL',
    PHARMA = 'PHARMA',
    UTILITIES = 'UTILITIES',
    OTHER = 'OTHER'
}

